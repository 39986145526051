<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Forma parte de nuestros distribuidores</h1>
                <p class="text-muted">Completa tus datos</p>
                <CCardTitle><center>{{  }}</center></CCardTitle>
                <CInput 
                    placeholder="Nombre Comercial"
                    v-model="form.comercialname"
                    required
                >
                    <template #prepend-content><font-awesome-icon icon="id-card" /></template>
                </CInput>
                <CInput 
                    placeholder="Dirección comercial"
                    v-model="form.address"
                    required
                >
                    <template #prepend-content><font-awesome-icon icon="id-card" /></template>
                </CInput>
                <CInput 
                    placeholder="Nombre contacto"
                    v-model="form.name"
                    required
                >
                    <template #prepend-content><font-awesome-icon icon="id-card" /></template>
                </CInput>
                <CInput
                    type="email"
                    placeholder="Correo contacto"
                    autocomplete="email"
                    v-model="form.email"
                    required
                >
                <template #prepend-content><font-awesome-icon icon="at" /></template>
                </CInput>
                <CInput
                    type="phone"
                    placeholder="Teléfono"
                    autocomplete="phone"
                    v-model="form.phone"
                >
                    <template #prepend-content><font-awesome-icon icon="phone" /></template>
                </CInput>
                <CCol col="12" sm="12" lg="12" v-show="alert.show">
                  <CAlert :color="alert.color" dismissible>
                    {{ alert.message }}
                  </CAlert>
                </CCol>
                <CButton color="success" block @click="save"><CSpinner v-if="loading" variant="grow" size="sm"/>Solicitar</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import UserResource from '@/api/user'
const userResource = new UserResource;

export default {
  name: 'Register',
  data() {
    return {
      loading: false,
      alert: {
        color: 'danger',
        message: '',
        show: false,
      },
      form: {
          comercialname: '',
          address: '',
          name: '',
          email: '',
          role: '',
          phone: '',
      },
    }
  },
  methods: {
    async save(){
      this.loading = true;
      await userResource.new(this.form).then(response => {
          this.alert.show = true;
          this.alert.message = "Gracias!, estamos analizando tus datos, en breve nos contactaremos contigo";
          this.alert.color = "success";
          this.loading = false;
        }).catch(error => {
          this.alert.show = true;
          this.alert.message = "Por verifica que todos los datos esten completos y correctos he intenta nuevamente";
          this.alert.color = "danger";
          this.loading = false;
        });
    },
  },
}
</script>
